<template>
  <div class="index row-me">

    <!--左侧布局-->
    <div class="left-div" :class="{ 'left-div-close': !isOpened }">
      <div class="posAbsoluteAll column-me row-center">
        <img src="../assets/img/logo11.png" class="img-logo1" draggable="false"/>
        <div class="menu-div flex1 column-me" v-dragscroll.y="true">
          <div class="w100Per">
            <el-menu active-text-color="#FFFFFF" background-color="#16295C"
                     :collapse="!isOpened" :default-active="defaultActive" text-color="#fff">
              <div v-for="(item,index) in menuArray" :key="index">
                <el-sub-menu :index="index+''" v-if="item.children.length>0&&item.show">
                  <template #title>
                    <img :src="item.icon" class="menu-icon">
                    <span class="mL8" v-if="isOpened">{{ item.name }}</span>
                  </template>
                  <div v-for="(item1,index1) in item.children" :key="index1">
                    <el-menu-item :index="index+'-'+index1" @click="clickMenu(item1.url)" v-if="item1.show">
                      {{ item1.name }}
                    </el-menu-item>
                  </div>
                </el-sub-menu>
                <el-menu-item :index="index+''" @click="clickMenu(item.url)" v-if="item.children.length===0&&item.show">
                  <img :src="item.icon" class="menu-icon">
                  <template #title>
                    <span class="mL8">{{ item.name }}</span>
                    <div class="red-point row-me center-all" v-if="item.url==='/warningMessage'&&warningNumber>0">
                      <span>{{ warningNumber > 99 ? '99+' : warningNumber }}</span>
                    </div>
                  </template>
                </el-menu-item>
              </div>
            </el-menu>
          </div>
        </div>
      </div>
    </div>

    <!--右侧布局-->
    <div class="right-div column-me flex1" style="overflow: auto">
      <div class="top-div row-me row-center">
        <img src="../assets/img/menu.png" class="mL20 pointer" title="合上" @click="clickClose" v-if="isOpened"/>
        <img src="../assets/img/menu1.png" class="mL20 pointer" title="展开" @click="clickClose" v-else/>
        <div class="flex1"></div>
        <el-popover placement="bottom" width="160" trigger="hover">
          <template #reference>
            <div class="row-me row-center">
              <el-image :src="user.avatar" :preview-src-list="[user.avatar]" fit="cover" class="img-header">
                <template #error>
                  <img src="../assets/img/header.png" slot="error" class="img-header objFitCover" draggable="false"/>
                </template>
              </el-image>
              <div class="title1 mL10" v-if="user">
                {{ user.nickName ? user.nickName : '未知用户' }}
              </div>
              <img src="../assets/img/xia.png" class="img20 objScaleDown mL10 mR20" draggable="false">
            </div>
          </template>
          <div class="column-me">
            <div class="h40 line-height40 align-center text-black-point" @click="clickPassword">修改密码</div>
            <div class="h40 line-height40 align-center text-black-point" @click="clickExit">退出登录</div>
          </div>
        </el-popover>
      </div>
      <div class="main-content column-me w100Per flex1">
        <router-view></router-view>
      </div>
    </div>


    <!-- 修改密码 -->
    <el-dialog title="修改密码" v-model="visiblePassword" width="35%" top="10vh" :destroy-on-close="true">
      <div class="padding20">
        <div class="row-me row-center">
          <div class="w90 text-justify">当前账号</div>
          <div>：</div>
          <div class="flex1 mL10">{{ user.userName }}</div>
        </div>
        <div class="row-me row-center mT20">
          <div class="w90 text-justify">原密码</div>
          <div>：</div>
          <div class="flex1">
            <el-input placeholder="请输入6-12位密码" style="width: 100%;" :type="pwdType0" clearable :minlength="6"
                      :maxlength="12" v-model="password0" :class="{'red-border':showRedBorder&&password0===''}">
            </el-input>
          </div>
          <img
              :src="pwdType0==='password'?require('../assets/img/eye_icon.png'):require('../assets/img/not_eye_icon.png')"
              class="img-icon mL15 pointer" @click="clickPwd0">
        </div>
        <div class="row-me row-center mT20">
          <div class="w90 text-justify">新密码</div>
          <div>：</div>
          <div class="flex1">
            <el-input placeholder="请输入6-12位密码" style="width: 100%;" :type="pwdType1" clearable :minlength="6"
                      :maxlength="12" v-model="password1" :class="{'red-border':showRedBorder&&password1===''}">
            </el-input>
          </div>
          <img
              :src="pwdType1==='password'?require('../assets/img/eye_icon.png'):require('../assets/img/not_eye_icon.png')"
              class="img-icon mL15 pointer" @click="clickPwd1">
        </div>
        <div class="row-me row-center mT20">
          <div class="w90 text-justify">确认新密码</div>
          <div>：</div>
          <div class="flex1">
            <el-input placeholder="请输入6-12位密码" style="width: 100%;" :type="pwdType2" clearable :minlength="6"
                      :maxlength="12" v-model="password2" :class="{'red-border':showRedBorder&&password2===''}">
            </el-input>
          </div>
          <img
              :src="pwdType2==='password'?require('../assets/img/eye_icon.png'):require('../assets/img/not_eye_icon.png')"
              class="img-icon mL15 pointer" @click="clickPwd2">
        </div>
        <div class="row-me row-center flex-end1 mT100">
          <div class="btn-blank w140 btn-height40" @click="clickCancel">取消</div>
          <div class="btn-blue w140 btn-height40 mL20" @click="clickSave">提交</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {menuList} from '@/assets/js/menu_list'

export default {
  components: {},
  data() {
    return {
      isOpened: true, //菜单展开
      menuArray: [], //左侧菜单
      user: {}, //当前登录用户
      visiblePassword: false,
      password0: '',
      password1: '',
      password2: '',
      pwdType0: 'password',
      pwdType1: 'password',
      pwdType2: 'password',
      showRedBorder: false,
      defaultActive: '0-0', //默认活跃的菜单
      permissions: [],//权限菜单
      warningNumber: 0,//预警消息数量
      warningNumberOld: 0,//预警消息数量
      timeout: null,
      isPermissionGranted: false,//是否有通知权限
      notify: null,
      timeoutNotify: null,
    }
  },
  // html加载完成之前
  created() {
    let token = sessionStorage.getItem('token')
    if (this.$method.isEmpty(token)) {
      this.$router.replace({
        path: '/login'
      })
      return
    } else {
      this.getUserInfo()
      this.getWarningNumber()
      this.triggerNotification()
    }
    let menuOpen = localStorage.getItem("menuOpen");
    this.isOpened = menuOpen === null || menuOpen === true || menuOpen === "true";
  },
  // html加载完成后执行。
  mounted() {
    this.checkNotificationPermission()
  },
  unmounted() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (this.timeoutNotify) {
      clearTimeout(this.timeoutNotify)
    }
  },
  // 事件方法执行
  methods: {
    //初始化选中菜单
    initMenu(menuArray) {
      let parentPath = '';
      let path = this.$route.path; //如："/aaa/bbb"
      let arr = path.split('/') //获取父级路径，防止有详情等多级页面
      for (const item of arr) {
        if (!this.$method.isEmpty(item)) {
          parentPath = '/' + item; //父级路径，如："/aaa"
          break;
        }
      }
      //先设置显示隐藏
      for (let menu1 of menuArray) {
        menu1.show = this.permissions.indexOf(menu1.url) !== -1
        if (menu1.children && menu1.children.length > 0) {
          let twoShowNum = 0
          for (let menu2 of menu1.children) {
            menu2.show = this.permissions.indexOf(menu2.url) !== -1
            if (menu2.show) {
              twoShowNum++;
            }
          }
          //如果二级显示，那么需要让一级菜单也显示
          if (twoShowNum > 0) {
            menu1.show = true
          }
        }
      }
      this.menuArray = menuArray
      //设置默认选中的菜单
      this.setMenuChecked(parentPath)
    },
    //菜单点击
    clickMenu(e) {
      this.$router.push({
        path: e,
        query: {}
      })
    },
    clickPwd0() {
      this.pwdType0 = this.pwdType0 === 'password' ? 'text' : 'password'
    },
    clickPwd1() {
      this.pwdType1 = this.pwdType1 === 'password' ? 'text' : 'password'
    },
    clickPwd2() {
      this.pwdType2 = this.pwdType2 === 'password' ? 'text' : 'password'
    },
    //取消
    clickCancel() {
      this.visiblePassword = false
    },
    //提交
    clickSave() {
      if (this.password0 === '' || this.password1 === '' || this.password2 === '') {
        this.showRedBorder = true
        return
      }
      if (this.password1.length < 6) {
        this.$message.error('密码不能低于6位')
        return
      }
      if (this.password1 !== this.password2) {
        this.$message.error('两次新密码不一致')
        return
      }
      this.$putForm('/system/user/profile/updatePwd', {
        oldPassword: this.password0,
        newPassword: this.password1,
      }).then((res) => {
        if (res) {
          this.visiblePassword = false
          this.$message.success('修改成功，请重新登录')
          setTimeout(() => {
            this.$router.replace({
              path: '/login'
            })
          }, 1000)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //修改密码
    clickPassword() {
      this.visiblePassword = true
    },
    //退出登录
    clickExit() {
      this.$confirm('您确定要退出登录吗？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('token')
        this.$router.replace({
          path: '/login',
          params: {}
        })
      }).catch(() => {

      });
    },
    //菜单展开合并
    clickClose() {
      this.isOpened = !this.isOpened;
      localStorage.setItem("menuOpen", this.isOpened);
    },
    //获取用户信息
    getUserInfo() {
      this.$get('/getInfo', {}).then((res) => {
        if (res) {
          sessionStorage.setItem('user', JSON.stringify(res.data.user))
          this.user = res.data.user
          this.permissions = res.data.permissions
          let menuArray = menuList()
          this.initMenu(menuArray)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取预警消息数量
    getWarningNumber() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.$get('/warnings/warningUpdateCount', {}).then((res) => {
        if (res) {
          this.warningNumber = res.data
          if (this.warningNumber > 0 && this.warningNumber > this.warningNumberOld) {
            this.triggerNotification()
          }
          this.warningNumberOld = this.warningNumber
          let path = this.$route.path
          if (path === '/warningMessage') {//当前在预警消息页面，刷新快一点
            this.timeout = setTimeout(() => {
              this.getWarningNumber()
            }, 2000)
          } else {
            this.timeout = setTimeout(() => {
              this.getWarningNumber()
            }, 6000)
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    // 检查通知权限
    checkNotificationPermission() {
      if (window.Notification && Notification.permission === 'granted') {
        this.isPermissionGranted = true
      }
    },
    // 触发通知
    triggerNotification() {
      if (this.timeoutNotify) {
        clearTimeout(this.timeoutNotify)
      }
      if (this.warningNumber > 0) {
        if (window.Notification && this.isPermissionGranted) {
          this.sendNotification('您有' + this.warningNumber + '条预警消息待处理')
        } else {
          // 请求权限
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              this.sendNotification('您有' + this.warningNumber + '条预警消息待处理')
            }
          }).catch(error => {
            console.log(error)
          });
        }
      }
      this.timeoutNotify = setTimeout(() => {
        this.triggerNotification()
      }, 1000 * 30)
    },
    //编辑通知内容并加上点击事件
    sendNotification(content) {
      let that = this
      if (this.notify) {
        this.notify.close()
      }
      this.notify = new Notification('预警消息待处理', {
        body: content,
        lang: 'zh-CN',
        icon: require('../../public/logo.png')
      })
      this.notify.onshow = function () {
        console.log('通知显示!')
      }
      this.notify.onclick = function () {
        window.focus()
        that.$router.push({
          path: '/warningMessage',
          query: {}
        })
        that.notify.close()
      }
      this.notify.onerror = function (e) {
        console.log('通知错误!')
        // 手动关闭
        that.notify.close()
      }
      this.notify.onclose = function () {
        console.log('通知关闭')
      }
    },
    //设置菜单选中效果
    setMenuChecked(parentPath) {
      for (let i = 0; i < this.menuArray.length; i++) {
        let menu = this.menuArray[i]
        if (menu.children.length > 0) { //有二级菜单
          for (let j = 0; j < menu.children.length; j++) {
            let child = menu.children[j]
            if (!child.show) continue
            if (parentPath === '' || parentPath === '/') { //跳转第一个二级菜单
              this.clickMenu(child.url)
              this.defaultActive = i + '-' + j
              return
            } else {
              if (child.url === parentPath) {
                this.defaultActive = i + '-' + j
              }
            }
          }
        } else { //无二级菜单
          if (!menu.show) continue
          if (parentPath === '' || parentPath === '/') { //跳转第一个菜单
            this.defaultActive = i + ''
            this.clickMenu(menu.url)
            this.defaultActive = i + ''
            return
          } else {
            if (menu.url === parentPath) {
              this.defaultActive = i + ''
            }
          }
        }
      }
    },
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (newVal.path === '/warningMessage') {
          this.getWarningNumber()
        }
        this.setMenuChecked(newVal.path)
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.index {
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  color: #222222;

  .el-menu {
    border: none;
  }

  &:deep(.el-sub-menu__title) {
    font-size: 16px;
  }

  .el-menu-item {
    font-size: 16px;
    padding-right: 0 !important;
  }

  .el-sub-menu .el-menu-item {
    font-size: 15px;
    padding-left: 50px !important;
    padding-right: 0 !important;
    min-width: auto;
  }

  .el-menu--collapse {
    width: 100%;
  }

  &:deep(.el-menu-item *) {
    vertical-align: middle;
  }

  .left-div {
    width: 200px;
    height: 100%;
    background: #16295C;
    position: relative;
    transition: 0.3s;

    &-close {
      width: 80px;
    }

    .menu-icon {
      width: 22px;
      height: 22px;
      object-fit: scale-down;
      display: inline;
    }

    .img-logo1 {
      width: 70%;
      margin-top: 20px;
      object-fit: scale-down;
    }

    &:deep(.el-menu-item:hover) {
      background: rgba(76, 123, 248, 0.5);
    }

    &:deep(.el-menu-item.is-active) {
      background: rgba(76, 123, 248, 0.5);
      border-left: 6px solid #4C7BF8;
    }

    &:deep(.el-menu .el-menu--inline) {
      background: #12224E;
    }

    .menu-div {
      width: 100%;
      margin-top: 20px;
      overflow: auto;
      align-items: flex-end;
      /* Firefox */
      scrollbar-width: none;
      /* IE 10+ */
      -ms-overflow-style: none;
      border-top: 1px solid rgba(236, 236, 236, 0.1);

      /* Chrome Safari */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .red-point {
      width: 25px;
      height: 25px;
      background: #FF2929;
      border-radius: 50%;
      font-size: 12px;
      color: #FFFFFF;
      margin-left: 10px;
    }
  }

  .right-div {
    height: 100%;

    .top-div {
      width: 100%;
      height: 60px;
      background: #FFFFFF;
      background-size: 100%;

      .img-header {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .title1 {
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 18px;
        color: #999999;
      }

      .line-shu {
        width: 1px;
        height: 24px;
        background: #ECECEC;
      }

      .title {
        font-size: 20px;
        color: #222222;
      }

      .img-icon {
        width: 30px;
        height: 30px;
        object-fit: scale-down;
      }
    }

    .second-menu-div {
      width: 160px;
      // width: 0;
      height: 100%;
      background: #ffffff;
      box-shadow: 5px 5px 10px #ccc;
      z-index: 10;
      overflow: auto;
      // animation: openMenu 0.2s 1 ease-out;
      transition: 0.3s;
      /* Firefox */
      scrollbar-width: none;
      /* IE 10+ */
      -ms-overflow-style: none;

      /* Chrome Safari */
      &::-webkit-scrollbar {
        display: none;
      }

      &-open {
        width: 160px;
      }

      .item-menu-two {
        height: 60px;
        line-height: 60px;
        text-align: left;
        padding-left: 20px;
        font-size: 18px;
        color: #666666;
        cursor: pointer;
        word-break: keep-all;
        text-overflow: ellipsis;

        &-active {
          background: #E8EEF9;
          font-weight: bold;
          color: #4C7BF8;
        }

        &:hover {
          background: #E8EEF9;
        }
      }
    }

    @keyframes openMenu {
      0% {
        width: 0;
      }

      100% {
        width: 160px;
      }
    }

    .main-content {
      font-size: 16px;
      color: #252630;
      background: #F8F8F8;
      overflow: auto;
    }
  }
}

.user-info-text {
  color: #000000;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}
</style>
