export function menuList() {
  let menuList = [{
    name: '工作台',
    url: '/home',
    icon: require('@/assets/img/icon_home.png'),
    children: [],
  }, {
    name: '设备管理',
    url: '/deviceList',
    icon: require('@/assets/img/icon_sbgl.png'),
    children: []
  }, {
    name: '预警消息管理',
    url: '/warningMessage',
    icon: require('@/assets/img/icon_yjxxgl.png'),
    children: []
  }, {
    name: '班次提醒管理',
    url: '/warningClass',
    icon: require('@/assets/img/icon_yjxxgl.png'),
    children: []
  }, {
    name: '健康知识库',
    url: '/safeManage',
    icon: require('@/assets/img/icon_nrgl.png'),
    children: [{
      name: '健康知识',
      url: '/safeManage'
    }, {
      name: '疾病预防',
      url: '/articleManage'
    }]
  }, {
    name: '用户管理',
    url: '/userManage',
    icon: require('@/assets/img/icon_yhgl.png'),
    children: []
  }, {
    name: '意见反馈',
    url: '/suggestManage',
    icon: require('@/assets/img/icon_yjfk.png'),
    children: []
  }, {
    name: '系统管理',
    url: '/systemUser',
    icon: require('@/assets/img/icon_xtgl.png'),
    children: [{
      name: '系统用户管理',
      url: '/systemUser'
    }, {
      name: '角色管理',
      url: '/roleManage'
    }, {
      name: '组织管理',
      url: '/orgManage'
    }, {
      name: '预警参数配置',
      url: '/paramManage'
    }, {
      name: '健康评估参数',
      url: '/health'
    }, {
      name: '系统参数',
      url: '/systemParam'
    }]
  }]
  return menuList
}
